/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import "./src/components/layout.css"

//check if client already has a cookie and activates tracking
export const onClientEntry = () => {
  window.onload = () => {
    if (document.cookie.includes("CookieConsent=true") && window._paq) {
      //@ts-ignore
      window._paq.push(["setConsentGiven"])
    }
  }
}
