module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BürgerBot","short_name":"BürgerBot","description":"Senden Sie Ihr Anliegen per Smartphone über den BürgerBot an Ihre Stadt! Mit mehr Bürgerbeteiligung und künstlicher Intelligenz zur digitalen Smart City.","start_url":"/","background_color":"#0175b2","theme_color":"#0175b2","lang":"de","display":"minimal-ui","icon":"src/images/logo-blue.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a231b3293928cd800da88da51a84d03"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.viind.com","siteUrl":"https://www.buergerbot.de","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","requireConsent":true,"trackLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
