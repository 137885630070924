// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buerger-tsx": () => import("./../../../src/pages/buerger.tsx" /* webpackChunkName: "component---src-pages-buerger-tsx" */),
  "component---src-pages-contact-bot-tsx": () => import("./../../../src/pages/contact-bot.tsx" /* webpackChunkName: "component---src-pages-contact-bot-tsx" */),
  "component---src-pages-datenschutz-bot-tsx": () => import("./../../../src/pages/datenschutz-bot.tsx" /* webpackChunkName: "component---src-pages-datenschutz-bot-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-staedte-index-tsx": () => import("./../../../src/pages/staedte/index.tsx" /* webpackChunkName: "component---src-pages-staedte-index-tsx" */),
  "component---src-pages-staedte-supported-city-name-tsx": () => import("./../../../src/pages/staedte/{SupportedCity.name}.tsx" /* webpackChunkName: "component---src-pages-staedte-supported-city-name-tsx" */)
}

